<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="false"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />

    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <v-layout wrap>
            <v-flex xs12 sm6 md8 text-left>
              <span class="nsbold" style="font-size: 20px"> Warden </span>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-1>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="Date"
                    outlined
                    readonly
                    dense
                    hide-details
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md2 pt-2 pt-sm-0 v-if="attendanceStatus == false" hidden-sm-and-down>
              <v-btn
                small
                color="green"
                outlined
                title="Approve Request"
                @click="approvedialoge = true"
                >Mark Present</v-btn
              >
            </v-flex>
             <v-flex xs12 sm3 md2 pt-2 pt-sm-0 v-if="attendanceStatus == false" hidden-md-and-up>
              <v-btn
                small
                color="green"
                outlined block
                title="Approve Request"
                @click="approvedialoge = true"
                >Mark Present</v-btn
              >
            </v-flex>
            <!-- <v-flex xs2 v-if="attendanceStatus == true">
              <v-btn
                small
                color="green"
                outlined
                title="Approve Request"
                @click="approvedialoge = true"
                >Mark Present</v-btn
              >
            </v-flex> -->
          </v-layout>
          <v-layout wrap v-if="attendanceStatus == false">
            <v-flex xs12 v-if="admins">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Role</th>
                      <th class="text-right">
                         <v-layout wrap justify-end>
                        <v-flex xs3>
                        <v-checkbox
                          v-model="isSelectAll"
                          hide-details
                          class="mt-0"
                          label="All"
                          @click="markAll()"
                        ></v-checkbox>
                          </v-flex>
                      </v-layout>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in admins" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>{{ value.name }}</td>
                      <td>{{ value.phone }}</td>
                      <td>{{ value.role }}</td>
                      <td class="text-right">
                      <v-layout wrap justify-end>
                        <v-flex xs2>
                            <v-checkbox
                          @click="selectThis(value.checkbox, value._id)"
                          v-model="value.checkbox"
                        ></v-checkbox>
                        </v-flex>
                      </v-layout>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
          <v-layout wrap v-else>
            <v-flex xs12 v-if="wardenList">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Role</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">
                       Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value2, i) in wardenList" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>{{ value2.staffId.name }}</td>
                      <td>{{ value2.staffId.phone }}</td>
                      <td>{{ value2.staffId.role }}</td>
                      <td>{{ value2.attandanceType }}</td>
                      <td>
                        <v-btn
                small v-if="value2.attandanceType=='Absent'"
                color="green"
                outlined
                title="Approve Request"
                @click="(approvedialoge2 = true)(idd=value2._id)"
                >Mark Present</v-btn
              >
               <v-btn
                small v-if="value2.attandanceType=='Present'"
                color="red"
                outlined
                title="Approve Request"
                @click="(approvedialoge2 = true)(idd=value2._id)"
                >Mark Absent</v-btn
              >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card>
        <!---------------dialogue----------------->
        <!-- <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text">
                <v-layout wrap>
                  <v-flex xs6> Admission No: {{ user.admissionNo }} </v-flex>
                  <v-flex xs6> Purpose: {{ user.purpose }} </v-flex>
                  <v-flex xs6> Name: {{ name }} </v-flex>
                  <v-flex xs6> From Date: {{ user.fromDate }} </v-flex>
                  <v-flex xs6> Class: {{ classs }} </v-flex>
                  <v-flex xs6> To: {{ user.toDate }} </v-flex>
                  <v-flex xs6> School: {{ school }} </v-flex>
                  <v-flex xs6> Destination: {{ user.destination }} </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="add()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        <!-- add -->

        <v-dialog width="400px" v-model="approvedialoge">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to Approve?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="approvedialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="approveData()"
                >Approve</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
          <v-dialog width="400px" v-model="approvedialoge2">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to Approve?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="approvedialoge2 = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="changeapproveData()"
                >Approve</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          :timeout="1000"
          :value="true"
          right
          color="red"
          v-model="snackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
      <v-flex xs12 py-2>
        <v-pagination
          small
          color="#766BC0"
          total-visible="7"
          v-model="page"
          :length="Pagelength"
        >
        </v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        switch1:false,
      snackbar: false,
      valid: true,
      valid1: true,
      idd:null,
      //delete
      curId: null,
      //edit
      curItem: null,
      editpassword: null,
      show1: false,
      menu:false,
      ServerError: false,
      appLoading: false,
      adddialogue: false,
      approvedialoge: false,
      approvedialoge2:false,
      deletedialoge: false,
      admins: [],
      wardenList: [],
      phone: null,
      password: null,
      roles: [
        "laundryadmin",
        "foodstockadmin",
        "AssetAdmin",
        "SecurityAdmin",
        "HostelWarden",
        "rector",
      ],
      role: null,
      msg: null,
      keyword: null,
      isSelectAll: false,
      selected: [],
      sflag: true,
      page: 1,
      Pagelength: 0,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      attendanceStatus: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        min: (v) => v.length >= 10 || "Min 10 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
     fromDate() {
      // this.getData();
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.admins.length; i++) {
          this.admins[i].checkbox = true;
          this.selected.push(this.admins[i]._id);
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.admins.length; i++) {
          this.admins[i].checkbox = false;
          this.selected = [];
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
      }

    },
    approveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/admin/mark/attendance/staff",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // id: this.curId,
          presentIds: this.selected,
          date: this.fromDate,
          //   page: this.page,
          //   limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.selected = [];
            this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            // this.admins = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    
     changeapproveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/staff/attendance/change/status",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // id: this.curId,
          id: this.idd,
        //   date: this.fromDate,
          //   page: this.page,
          //   limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.selected = [];
            this.approvedialoge2 = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/admin/get/attendance/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: this.fromDate,
          //    page: this.page,
          //   limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attendanceStatus = response.data.attandanceTaken;
            if (this.attendanceStatus == false) {
              this.getList();
            } else {
              this.wardenList = response.data.attandanceData.StaffDetails;
            }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/admin/get/all/wardens",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          //   date: this.fromDate,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.admins = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    addCategory() {
      if (this.$refs.form1.validate()) {
        axios({
          url: "/add/admin",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            phone: this.phone,
            password: this.password,
            role: this.role,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getData();
            }
            (this.phone = null),
              (this.password = null),
              (this.role = null),
              this.$refs.form1.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>
